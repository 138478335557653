<template>
  <div class="app-spinner-overlay">
    <svg
      viewBox="25 25 50 50"
      class="app-spinner"
    >
      <circle
        cx="50"
        cy="50"
        r="20"
        fill="none"
        class="path"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AppSpinner',
};
</script>

<style scoped>
  .app-spinner {
    height: 42px;
    width: 42px;
    margin-top: -100px;
    -webkit-animation: loading-rotate 2s linear infinite;
    animation: loading-rotate 2s linear infinite;
  }

  .app-spinner .path {
    -webkit-animation: loading-dash 1.5s ease-in-out infinite;
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90,150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: #409EFF;
    stroke-linecap: round;
  }

  .app-spinner-overlay {
    position : absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.71);
    display: grid;
    justify-content: center;
    align-items: center;
  }
</style>
